import React, {useState, useEffect, useCallback, useContext} from 'react';
import classNames from 'classnames';
import { Link } from "gatsby"
import Logo from '@components/Logo/Logo';
import { Search } from "iconoir-react";
import ThemeToggle from '@components/Theme/ThemeToggle';
import { useKBar } from "kbar";
import { StateContext } from "@context/StateProvider";
import { useTranslation } from "react-i18next";
import LanguageToggle from "../Language/LanguageToggle";
import AudioToggle from "../Audio/AudioToggle";

export function MobileNav({ showTitle }) {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [fixed, setFixed] = useState(false);
  const [hidden, setHidden] = useState(false);
  const { state } = useContext(StateContext);
  const { query } = useKBar();
  const { t } = useTranslation();

  const scrollOffset = 50

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset;
    setFixed(currentScrollPos > scrollOffset);
    setHidden(currentScrollPos > scrollOffset && prevScrollPos < currentScrollPos);
    setPrevScrollPos(currentScrollPos);
  }, [prevScrollPos])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, fixed, handleScroll]);

  const classes = classNames({
    'nav-mobile': true,
    'nav-mobile-fixed': fixed,
    'nav-mobile-hidden': hidden,
    'has-title': state.showTitle
  });

  return (
    <nav className={classes}>
      <div className="nav-logo">
        <Link to="/" className="logo" title="Entertainment Design Logo">
          <Logo />
        </Link>
      </div>
      <ul>
        <li className="nav-item">
          <ThemeToggle />
        </li>
        <li className="nav-item">
          <AudioToggle />
        </li>
      </ul>
      <ul>
        <li className="nav-item">
          <LanguageToggle />
        </li>
        <li className="nav-item">
          <button onClick={() => query.toggle()} className="nav-link" title="Search">
            <Search />
          </button>
        </li>
      </ul>
    </nav>
  );
}
