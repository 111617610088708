import React from 'react';

const Logo = ({ gradient, steps }) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 92.8" style={gradient ? { fill: `url(#logo-gradient)` } : null}>
		{gradient && <defs>
			<linearGradient id="logo-gradient">
				{[...Array(10).keys()].map(i => {
					i++
					return <stop key={i} offset={`${(100 / steps) * i}%`} className={`stop-color-${i}`}></stop>
				})}
			</linearGradient>
		</defs>}
		<polygon points="0 16 16 0 128 0 112 16 0 16" />
		<polygon points="0 54.4 16 38.4 128 38.4 112 54.4 0 54.4" />
		<polygon points="0 92.8 16 76.8 128 76.8 112 92.8 0 92.8" />
	</svg>
);

export default Logo;
