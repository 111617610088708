export const themes = [
  {
    id: 'dark',
    name: 'Dark',
    enable: true,
    palette: {
      primaryBg: '#131317',
      secondaryBg: '#232323',
      primaryText: '#fafafa',
      secondaryText: '#ededed',
      accent: '#339afe',
      shadow: '#000000',
    },
    avatar: {
      seba: 'seba_avatar_baby_yoda.jpg',
      paolo: 'paolo_avatar_studio.jpg'
    },
    home_hero: 'ed3sign_logo_bg_1.png',
    colors: ['#ff0080', '#FF2560', '#FF4B3D', '#FE7119', '#EF9316', '#BCA94B', '#85C183', '#50D9BD', '#4BC7D3', '#59A0D6', '#677AD8', '#7652DB']
  },
  {
    id: 'light',
    name: 'Light',
    enable: true,
    palette: {
      primaryBg: '#ffffff',
      secondaryBg: '#f7f9fc',
      primaryText: '#232323',
      secondaryText: '#383A3C',
      accent: '#339afe',
      shadow: '#505050',
    },
    avatar: {
      seba: 'seba_avatar_baby_gang.jpg',
      paolo: 'paolo_avatar_north.jpg'
    },
    home_hero: 'ed3sign_logo_bg_2.png',
    colors: ['#A85AEB', '#B550CA', '#C744AC', '#D93889', '#E43975', '#E85371', '#EC6C6B', '#F58368', '#F89667', '#F9A55D', '#FBB65B', '#FFC35A']
  },
  {
    id: 'pink',
    name: 'Come una Bimba',
    enable: true,
    palette: {
      primaryBg: '#fff0f0',
      secondaryBg: '#F8D4D5',
      primaryText: '#182C67',
      secondaryText: '#F582AE',
      accent: '#F582AE',
      shadow: '#091129',
    },
    avatar: {
      seba: 'seba_avatar_canada.jpg',
      paolo: 'paolo_avatar_redbull.jpg'
    },
    home_hero: 'ed3sign_logo_bg_3.png',
    colors: ['#8749F3', '#7A6CF7', '#6C90F7', '#5AB6F9', '#60C9F6', '#8AB6ED', '#B79FEA', '#E48AE2', '#F389D3', '#F790BB', '#FC96A4', '#FEA18C']
  },
  {
    id: 'light-blue',
    name: 'Carta da Zucchero',
    enable: true,
    palette: {
      primaryBg: '#CAE5F5',
      secondaryBg: '#a9d5ef',
      primaryText: '#182C67',
      secondaryText: '#F582AE',
      accent: '#F582AE',
      shadow: '#091129',
    },
    avatar: {
      seba: 'seba_avatar_droptop.jpg',
      paolo: 'paolo_avatar_drum.jpg'
    },
    home_hero: 'ed3sign_logo_bg_4.png',
    colors: ['#05B3FB', '#19B9FB', '#2DC1F7', '#42C9F8', '#5CCBF8', '#7FC1FC', '#A1B6FC', '#C6ABFF', '#C199F7', '#A880ED', '#8F68E2', '#7755DF']
  },
  {
    id: 'blue',
    name: 'Submariner',
    enable: true,
    palette: {
      primaryBg: '#0e223e',
      secondaryBg: '#132e52',
      primaryText: '#fafafa',
      secondaryText: '#ededed',
      accent: '#339afe',
      shadow: '#505050',
    },
    avatar: {
      seba: 'seba_avatar_key.jpg',
      paolo: 'paolo_avatar_live.jpg'
    },
    home_hero: 'ed3sign_logo_bg_5.png',
    colors: ['#5367E1', '#3F6EEB', '#2874F3', '#137BFA', '#0387FD', '#069AFE', '#05AFFB', '#04C3FA', '#1BD4FC', '#38E0FA', '#59EEFA', '#76FAF8']
  },
  {
    id: 'mgs3',
    name: 'MGS3',
    enable: true,
    palette: {
      primaryBg: '#1d271d',
      secondaryBg: '#364535',
      primaryText: '#fafafa',
      secondaryText: '#ededed',
      accent: '#5BBB60',
      shadow: '#1b241b',
    },
    avatar: {
      seba: 'seba_avatar_californication.jpg',
      paolo: 'paolo_avatar_jam.jpg'
    },
    home_hero: 'ed3sign_logo_bg_6.png',
    colors: ['#037263', '#1B8976', '#3BA380', '#59BF8F', '#79D497', '#96E09E', '#AFEDA0', '#CCFCA4', '#C9FFB3', '#B7FFC0', '#A3FFD0', '#90FFDF']
  },
  {
    id: 'giovedi-pizza',
    name: 'Giovedì Pizza',
    enable: false,
    palette: {
      primaryBg: '#000000',
      secondaryBg: '#131317',
      primaryText: '#fafafa',
      secondaryText: '#ededed',
      accent: '#f44336',
      shadow: '#1c0607',
    },
    avatar: {
      seba: 'seba_avatar_italiano.jpg',
      paolo: 'paolo_avatar_italiano.jpg'
    },
    home_hero: 'ed3sign_logo_bg_7.png',
    colors: ['#2FA969', '#2FA969', '#2FA969', '#2FA969', '#ffffff', '#FFFFFF', '#FBF2F2', '#E8ACAE', '#C83D41', '#C83D41', '#C83D41', '#f44336']
  },
];

export const defaultFontFamily = {
  default: '"Inter", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif',
  serif: '"Boska", "Lora", Georgia, serif',
  mono: '"Jetbrains Mono var", "Inconsolata", monospace',
}

export default themes
