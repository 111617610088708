import React from 'react';
import Copyright from '@components/Copyright/Copyright';
import ContactCta from '@components/Contact/ContactCta';
import { ChatBubbleTranslate } from "iconoir-react";
import Logo from "../Logo/Logo";
import AudioToggle from "@components/Audio/AudioToggle";
import ThemeToggle from '@components/Theme/ThemeToggle';
import LanguageToggle from "@components/Language/LanguageToggle";
import { Waves } from "@components/3D";
import { footerRoutes as routes, LEGAL_PAGE } from '@data/routes';
import { useTranslation } from "react-i18next";
import { Link } from "gatsby";
import { COMPANY_ADDRESS, COMPANY_NAME, REA_NUMBER, SHARE_CAPITAL, VAT_NUMBER, RECEIVER_CODE } from "@data/config";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <ContactCta />
      <div className="footer-wrapper">
        <footer className="footer">
          <div className="footer-content">
            <div className="footer-grid">
              <div className="footer-col">
                <div className="footer-logo mb-4">
                  <Logo />
                  <h5>Entertainment<br /> Design</h5>
                </div>

                <h6>{COMPANY_NAME}</h6>
                <p>
                  {t(COMPANY_ADDRESS.street)}<br />
                  {t(COMPANY_ADDRESS.zip)}{' '}{t(COMPANY_ADDRESS.city)}{', '}{t(COMPANY_ADDRESS.country)}<br />
                  {t('P.IVA')} {VAT_NUMBER}<br />
                  {REA_NUMBER}<br />
                  {t('Share Capital')}{' '}{SHARE_CAPITAL}<br />
                  {RECEIVER_CODE}
                </p>
              </div>
              <div className="footer-col">
                <h5>{t('Quick Links')}</h5>
                <ul className="footer-nav">
                  {routes.map(link => link.show && (
                    <li className="nav-item" key={link.to}>
                      <Link className="nav-link" to={link.to} title={t(link.name)} activeClassName="active">
                        <span className="nav-link-label">{t(`nav.${link.name.toLowerCase()}`)}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="footer-col">
                <h5>{t('Make yourself at home')}</h5>
                <ul className="footer-nav">
                  <li className="nav-item">
                    <ThemeToggle showLabel />
                  </li>
                  <li className="nav-item">
                    <AudioToggle showLabel />
                  </li>
                  <li className="nav-item">
                    <LanguageToggle
                      showLabel
                      icon={<ChatBubbleTranslate />}
                    />
                  </li>
                </ul>
              </div>
              <div className="footer-col">
                <h5>{t('Boring legal stuff')}</h5>
                <ul className="footer-nav">
                  <li className="nav-item">
                    <Link className="nav-link" to={LEGAL_PAGE} title={t('Privacy Policy')} activeClassName="active">
                      <span className="nav-link-label">
                        {t('Legal')}
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Copyright />
          <Waves
            className="footer-waves"
            overlay={false}
          />
        </footer>
        <div className="footer-3d"></div>
      </div>
    </>
  );
}

export default Footer
