import React, {useContext} from 'react';
import {SoundHigh, SoundOff, Voice} from 'iconoir-react';
import { ThemeContext } from '@context/ThemeProvider';
import classNames from "classnames";
import { useTranslation } from "react-i18next";

export function AudioToggle({ showLabel, className }) {
  const togglePicker = (e) => {
    e.preventDefault();
    const picker = document.querySelector('.audio-picker');
    picker.classList.toggle('is-open');
  };

  const { getCurrentAudio, isPlaying } = useContext(ThemeContext);
  const audio = getCurrentAudio()
  const { t } = useTranslation();

  const classes = classNames({
    'btn': true,
    'audio-toggle': true,
    'nav-link': true,
    'btn-toggle': !showLabel,
    [className]: className
  });

  return (
    <button aria-label={t('Audio Toggle')} onClick={togglePicker} className={classes}>
      {!showLabel && <Voice />}
      {showLabel && !isPlaying && <SoundOff />}
      {showLabel && isPlaying && <SoundHigh />}
      {showLabel && audio.name}
    </button>
  );
}

export default AudioToggle;
