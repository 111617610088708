import React from "react";
import {navigate} from "gatsby";
import {Code, FaceId, Home, SendMail} from "iconoir-react";
import projects from '@data/projects.json'
import { routes } from '@data/routes'

const routesData = routes.map(route => {
  return {
    id: route.name,
    name: route.name,
    shortcut: route.shortcut,
    keywords: route.keywords,
    section: "Navigation",
    perform: () => navigate(route.to)
  }
})

let projectsData = projects.map(project => {
  const tags = project.tags.join(' ')
  return {
    id: project.slug,
    name: project.title,
    keywords: `${project.keywords} ${tags}`,
    section: 'Projects',
    perform: () => navigate(`/${project.slug}`)
  }
})

// Alphabetical order
projectsData = projectsData.sort((a, b) => a.name.localeCompare(b.name))

export const actions = [
  ...routesData,
  ...projectsData
]
