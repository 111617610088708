import React from 'react';
import {
	SunLight,
	HalfMoon,
	Snow,
	Rain,
	Tunnel
} from 'iconoir-react';

export const audios = [
	{
		id: 'forest-day',
		name: 'Forest Day',
		file: '/audio/forest_day.mp3',
		icon: <SunLight />,
		is8D: true
	},
	{
		id: 'forest-night',
		name: 'Forest Night',
		file: '/audio/forest_night.mp3',
		icon: <HalfMoon />,
		is8D: true
	},
	{
		id: 'snowy-mountain',
		name: 'Snowy Mountain',
		file: '/audio/snowy_mountain.mp3',
		icon: <Snow />,
		is8D: true
	},
	{
		id: 'rainstorm',
		name: 'Rainstorm',
		file: '/audio/rainstorm.mp3',
		icon: <Rain />,
		is8D: true
	},
	{
		id: 'cavern',
		name: 'Cavern',
		file: '/audio/cavern.mp3',
		icon: <Tunnel />,
		is8D: true
	}
];

export default audios
