import React from 'react'
import {
  Home,
  BubbleStar,
  ChatLines,
  Code,
  FaceId,
  QuoteMessage,
  SelectWindow,
  JournalPage
} from "iconoir-react";

export const HOME_PAGE = '/'
export const WORK_PAGE = '/work'
export const SERVICES_PAGE = '/services'
export const PITCH_PAGE = '/pitch'
export const BLOG_PAGE = '/blog'
export const IDENTITY_PAGE = '/identity'
export const CONTACT_PAGE = '/contact'
export const QUOTES_PAGE = '/quotes'
export const LEGAL_PAGE = '/legal'
export const REFERRAL_PAGE = '/referral'
export const PARTNERS_PAGE = '/partners'

export const routes = [
  {
    name: 'Home',
    icon: <Home />,
    to: HOME_PAGE,
    show: true,
    keywords: 'home',
    shortcut: ['h']
  },
  {
    name: 'Work',
    icon: <Code />,
    to: WORK_PAGE,
    show: false,
    keywords: "work",
    shortcut: ["w"],
  },
  {
    name: 'Services',
    icon: <Code />,
    to: SERVICES_PAGE,
    show: true,
    keywords: "services",
    shortcut: ["s"],
  },
  {
    name: 'Identity',
    icon: <FaceId />,
    to: IDENTITY_PAGE,
    show: true,
    keywords: "identity ed3sign entertainment design",
    shortcut: ["i"],
  },
  {
    name: 'Blog',
    icon: <JournalPage />,
    to: BLOG_PAGE,
    show: true,
    keywords: "blog ed3sign entertainment design",
    shortcut: ["b"],
  },
  {
    name: 'Contact',
    icon: <ChatLines />,
    to: CONTACT_PAGE,
    show: true,
    keywords: "email contact project",
    shortcut: ["c"],
  },
  {
    name: 'Quotes',
    icon: <QuoteMessage />,
    to: QUOTES_PAGE,
    show: false,
    keywords: "quotes inspiration",
    shortcut: ["q"],
  }
];

export const mobileRoutes = [
  {
    name: 'Work',
    icon: <Code />,
    to: WORK_PAGE,
    show: false
  },
  {
    name: 'Services',
    icon: <Code />,
    to: SERVICES_PAGE,
    show: true
  },
  {
    name: 'Identity',
    icon: <FaceId />,
    to: IDENTITY_PAGE,
    show: true
  },
  {
    name: 'Home',
    icon: <Home />,
    to: HOME_PAGE,
    show: true
  },
  {
    name: 'Blog',
    icon: <JournalPage />,
    to: BLOG_PAGE,
    show: true
  },
  {
    name: 'Contact',
    icon: <ChatLines />,
    to: CONTACT_PAGE,
    show: true
  },
];

export const footerRoutes = [
  {
    name: 'Home',
    icon: <Home />,
    to: HOME_PAGE,
    show: true
  },
  {
    name: 'Work',
    icon: <Code />,
    to: WORK_PAGE,
    show: true
  },
  {
    name: 'Services',
    icon: <Code />,
    to: SERVICES_PAGE,
    show: true
  },
  {
    name: 'Identity',
    icon: <FaceId />,
    to: IDENTITY_PAGE,
    show: true
  },
  {
    name: 'Pitch',
    icon: <SelectWindow />,
    to: PITCH_PAGE,
    show: true
  },
  {
    name: 'Referral',
    icon: <BubbleStar />,
    to: REFERRAL_PAGE,
    show: true
  },
  {
    name: 'Contact',
    icon: <ChatLines />,
    to: CONTACT_PAGE,
    show: true
  },
];

export default routes
