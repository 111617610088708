import React, {useState, useEffect, useCallback, useContext} from 'react';
import classNames from 'classnames';
import { Link, navigate } from "gatsby"
import Image from "@components/Image/Image";
import { mobileRoutes as routes } from '@data/routes';
import { StateContext } from "@context/StateProvider";
import { useTranslation } from "react-i18next";
import {ArrowLeft, ChatLines} from "iconoir-react";
import {CONTACT_PAGE} from "../../data/routes";

export function TabNav() {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [fixed, setFixed] = useState(false);
  const [hidden, setHidden] = useState(false);
  const { state } = useContext(StateContext);
  const { t } = useTranslation();

  const scrollOffset = 50

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset;
    setFixed(currentScrollPos > scrollOffset);
    setHidden(currentScrollPos > scrollOffset && prevScrollPos < currentScrollPos);
    setPrevScrollPos(currentScrollPos);
  }, [prevScrollPos])

  const renderTabs = (routes, onClick) => {
    return routes.map((link) => link.show && (
      <li className="nav-item" key={link.to}>
        <Link className="nav-link" to={link.to} title={t(link.name)} onClick={onClick} activeClassName="active">
          {link.icon && link.icon}
          <span className="nav-link-label">{t(`nav.${link.name.toLowerCase()}`)}</span>
          <span className="nav-link-indicator"></span>
        </Link>
      </li>
    ));
  };

  const renderStatus = () => {
    if (state) {
      return (
        <div className="tab-nav-status">
          <button className="nav-link" onClick={() => navigate(-1)} title="Back">
            <ArrowLeft />
          </button>
          <div className="tab-nav-title">
            {state.image &&
              <Image
                src={state.image}
                alt={state.title}
                className="tab-nav-image"
              />
            }
            <h3>{state.title}</h3>
          </div>
          <Link className="nav-link" to={CONTACT_PAGE} title={t('Contacts')}>
            <ChatLines />
          </Link>
        </div>
      )
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, fixed, handleScroll]);

  const classes = classNames({
    'tab-nav': true,
    'tab-nav-fixed': fixed,
    'tab-nav-hidden': hidden,
    'has-title': state.showTitle
  });

  return (
    <nav className={classes}>
      {!state.showTitle &&
        <ul>
          {renderTabs(routes)}
        </ul>
      }
      {state.showTitle && renderStatus()}
    </nav>
  );
}
