import React from 'react'
import StateProvider from '@context/StateProvider'
import ThemeProvider from '@context/ThemeProvider'
import ViewportProvider from '@context/ViewportProvider'
import { KBarProvider } from "kbar";
import { IconoirProvider } from 'iconoir-react'
import {I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { actions } from '@data/actions'
import LanguageDetector from 'i18next-browser-languagedetector';

const i18n = i18next
  .use(LanguageDetector)
  .init({
    localeJsonSourceName: `locale`,
    fallbackLng: `it`,
    defaultLanguage: `it`,
    supportedLngs: [`en`, `it`],
    preload: [`en`, `it`],
    interpolation: { escapeValue: false },
    resources: {
      en: {
        translation: require("../../locales/en/translation.json")
      },
      it: {
        translation: require("../../locales/it/translation.json")
      }
    }
  });

export default function RootLayout({ children }) {
  return (
    <StateProvider>
      <I18nextProvider i18n={i18next}>
        <ThemeProvider>
          <IconoirProvider iconProps={{ strokeWidth: 1.25 }}>
            <ViewportProvider>
              <KBarProvider actions={actions} options={{ enabledHistory: true }}>
                {children}
              </KBarProvider>
            </ViewportProvider>
          </IconoirProvider>
        </ThemeProvider>
      </I18nextProvider>
    </StateProvider>
  );
}
