exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-identity-js": () => import("./../../../src/pages/identity.js" /* webpackChunkName: "component---src-pages-identity-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-manifesto-js": () => import("./../../../src/pages/manifesto.js" /* webpackChunkName: "component---src-pages-manifesto-js" */),
  "component---src-pages-paolo-js": () => import("./../../../src/pages/paolo.js" /* webpackChunkName: "component---src-pages-paolo-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-pitch-js": () => import("./../../../src/pages/pitch.js" /* webpackChunkName: "component---src-pages-pitch-js" */),
  "component---src-pages-quotes-js": () => import("./../../../src/pages/quotes.js" /* webpackChunkName: "component---src-pages-quotes-js" */),
  "component---src-pages-referral-js": () => import("./../../../src/pages/referral.js" /* webpackChunkName: "component---src-pages-referral-js" */),
  "component---src-pages-seba-js": () => import("./../../../src/pages/seba.js" /* webpackChunkName: "component---src-pages-seba-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-post-js-content-file-path-src-blog-lettera-agli-investitori-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/src/blog/lettera-agli-investitori/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-lettera-agli-investitori-index-mdx" */),
  "component---src-templates-post-js-content-file-path-src-blog-why-index-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/src/blog/why/index.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-blog-why-index-mdx" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

