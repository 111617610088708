import React from "react";
import { Link } from 'gatsby';
import {useTranslation} from "react-i18next";
import {CONTACT_PAGE} from "@data/routes";
import parse from 'html-react-parser';
function ContactCta() {
  const { t } = useTranslation();

  return (
    <Link to={CONTACT_PAGE} className="contact-cta-wrapper">
      <div className="contact-cta-3d"></div>
      <div className="contact-cta">
        <div className="contact-cta-content">
          <h5>{parse(t('contact_cta.title'))}</h5>
          <p>{t('contact_cta.subtitle')}</p>
        </div>
      </div>
    </Link>
  )
}

export default ContactCta
