import React, { useState, useContext, useRef } from 'react';
import classNames from 'classnames';
import { audios } from '@data/audio';
import { ThemeContext } from '@context/ThemeProvider';
import useOutsideEvent from '@utils/useOutsideEvent';
import {useTranslation} from "react-i18next";
import { Cancel, SoundHigh, SoundOff } from "iconoir-react";

export function AudioPicker() {
  const { currentAudio, applyAudio, isPlaying, setIsPlaying } = useContext(ThemeContext);
  const { t } = useTranslation();
  const pickerRef = useRef(null);

  const closePicker = (e) => {
    if (e) e.preventDefault();
    const menu = document.querySelector('.audio-picker');
    menu.classList.remove('is-open');
  };

  const pauseAll = () => {
    document.querySelectorAll('audio').forEach(el => el.pause());
  }

  const playAudio = (audio) => {
    const player = document.getElementById(`audio-${audio}`);
    if (player && player.paused) {
      pauseAll();
      player.play();
      setIsPlaying(true)
    } else {
      player.pause()
      setIsPlaying(false)
    }
    applyAudio(audio)
  }

  const renderItems = () => {
    return audios.map(function (item) {
      const classes = classNames({
        'audio-card': true,
        [`audio-${item.id}`]: item.id,
        'active': item.id === currentAudio,
      })

      const playClasses = classNames({
        'audio-play-icon': true,
        'playing': isPlaying && item.id === currentAudio
      })

      return (
        <div
          key={item.id}
          className={classes}
          onClick={() => playAudio(item.id)}
        >
          <div className="audio-title">
            {item.name}
            {item.is8D && <span>8D</span>}
          </div>
          <div className="audio-icon">
            {item.icon && item.icon}
          </div>
          <div className={playClasses}>
            {isPlaying && item.id === currentAudio ? <SoundHigh /> : <SoundOff />}
          </div>
          <audio id={`audio-${item.id}`} loop>
            <source src={item.file} type="audio/mp3" />
          </audio>
        </div>
      );
    });
  };

  const isPickerOpen = () => {
    const isToggle = event.target.classList.contains('audio-toggle')
    if(isToggle) return
    return pickerRef.current.classList.contains('is-open')
  }

  useOutsideEvent(pickerRef, () => {
    closePicker()
  }, isPickerOpen)

  return (
    <div ref={pickerRef} className="picker audio-picker">
      <div className="picker-title">{t('Select Audio')}</div>
      <button aria-label={t('Close')} onClick={closePicker} className="picker-close">
        <Cancel />
      </button>
      <div className="audio-list">{renderItems()}</div>
    </div>
  );
}
