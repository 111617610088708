import React, {useState, useEffect, useContext} from 'react'
import {BREAKPOINT_XS, BREAKPOINT_SM, BREAKPOINT_MD, BREAKPOINT_LG, BREAKPOINT_XL, BREAKPOINT_2XL} from "@data/config";

export const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
  const [screen, setScreen] = useState({
    eq: { xs: false, sm: false, md: false, lg: false, xl: false, xxl: false },
    lt: { xs: false, sm: false, md: false, lg: false, xl: false, xxl: false },
    gt: { xs: false, sm: false, md: false, lg: false, xl: false, xxl: false },
  })
  const [width, setWidth] = useState()
  const [height, setHeight] = useState()

  const getBreakpoints = (width) => {
    if(width <= BREAKPOINT_XS) {
      return {
        eq: {xs: true, sm: false, md: false, lg: false, xl: false, xxl: false},
        lt: {xs: true, sm: true, md: true, lg: true, xl: true, xxl: true},
        gt: {xs: false, sm: false, md: false, lg: false, xl: false, xxl: false},
      }
    } else if(width > BREAKPOINT_XS && width <= BREAKPOINT_SM) {
      return {
        eq: {xs: false, sm: true, md: false, lg: false, xl: false, xxl: false},
        lt: {xs: false, sm: true, md: true, lg: true, xl: true, xxl: true},
        gt: {xs: true, sm: false, md: false, lg: false, xl: false, xxl: false},
      }
    } else if(width > BREAKPOINT_SM && width <= BREAKPOINT_MD) {
      return {
        eq: {xs: false, sm: false, md: true, lg: false, xl: false, xxl: false},
        lt: {xs: false, sm: false, md: true, lg: true, xl: true, xxl: true},
        gt: {xs: true, sm: true, md: false, lg: false, xl: false, xxl: false},
      }
    } else if(width > BREAKPOINT_MD && width <= BREAKPOINT_LG) {
      return {
        eq: {xs: false, sm: false, md: false, lg: true, xl: false, xxl: false},
        lt: {xs: false, sm: false, md: false, lg: true, xl: true, xxl: true},
        gt: {xs: true, sm: true, md: true, lg: false, xl: false, xxl: false},
      }
    } else if(width > BREAKPOINT_LG && width <= BREAKPOINT_XL) {
      return {
        eq: {xs: false, sm: false, md: false, lg: false, xl: true, xxl: false},
        lt: {xs: false, sm: false, md: false, lg: false, xl: true, xxl: true},
        gt: {xs: true, sm: true, md: true, lg: true, xl: false, xxl: false},
      }
    } else if(width > BREAKPOINT_XL && width <= BREAKPOINT_2XL) {
      return {
        eq: {xs: false, sm: false, md: false, lg: false, xl: false, xxl: true},
        lt: {xs: false, sm: false, md: false, lg: false, xl: false, xxl: true},
        gt: {xs: true, sm: true, md: true, lg: true, xl: true, xxl: false},
      }
    } else if(width > BREAKPOINT_2XL) {
      return {
        eq: {xs: false, sm: false, md: false, lg: false, xl: false, xxl: false},
        lt: {xs: false, sm: false, md: false, lg: false, xl: false, xxl: false},
        gt: {xs: true, sm: true, md: true, lg: true, xl: true, xxl: true},
      }
    }
  }

  const handleWindowResize = () => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
    const breakpoints = getBreakpoints(window.innerWidth)
    setScreen(breakpoints)
  }

  useEffect(() => {
    const breakpoints = getBreakpoints(window.innerWidth)
    setScreen(breakpoints)

    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, []);

  return (
    <viewportContext.Provider value={{
      width,
      height,
      screen,
      BREAKPOINT_XS,
      BREAKPOINT_SM,
      BREAKPOINT_MD,
      BREAKPOINT_LG,
      BREAKPOINT_XL,
      BREAKPOINT_2XL
    }}>
      {children}
    </viewportContext.Provider>
  );
};

export const useViewport = () => {
  const {
    width,
    height,
    screen,
    BREAKPOINT_XS,
    BREAKPOINT_SM,
    BREAKPOINT_MD,
    BREAKPOINT_LG,
    BREAKPOINT_XL,
    BREAKPOINT_2XL
  } = useContext(viewportContext);

  return {
    width,
    height,
    screen,
    BREAKPOINT_XS,
    BREAKPOINT_SM,
    BREAKPOINT_MD,
    BREAKPOINT_LG,
    BREAKPOINT_XL,
    BREAKPOINT_2XL
  };
}

export default ViewportProvider
