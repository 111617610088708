/* helpers.js */

import { OG_DEFAULT_PATH } from "@data/config";
import { defaultFontFamily } from "@data/themes";
import { ColorFilter, Solver } from "./colorFilter";

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const hexToRgb = hex =>
  hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
    , (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16))

export const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

const applyFontFamily = (fontFamily) => {
  document.documentElement.style.setProperty('--font-family-default', fontFamily.default);
  document.documentElement.style.setProperty('--font-family-sans-serif', fontFamily.default);
  document.documentElement.style.setProperty('--font-family-serif', fontFamily.serif);
  document.documentElement.style.setProperty('--font-family-mono', fontFamily.mono);
}

const loadFont = (name, path, variants, formats) => {
  variants.forEach(variant => {
    const fontFiles = formats.map(format => {
      return `url(${path}-${variant}.${format}) format(${format})`
    })
    const font = new FontFace(name, fontFiles.join(','));
    font.load().then((loadedFont) => {
      document.fonts.add(loadedFont)
    });
  })
}

export const dashed = str => {
  return str.replace(/[A-Z]/g, m => `-${m.toLowerCase()}`)
}

export const isFunction = (x) => {
  return Object.prototype.toString.call(x) == '[object Function]';
}

export const routeMatches = (route, path) => {
  return route.replace(/^\/|\/$/g, '') === path.replace(/^\/|\/$/g, '')
}

export const setThemeVariables = (theme) => {

  document.body.classList.remove(...document.body.classList);
  if (theme?.id) {
    document.body.classList.add(`theme-${theme.id}`);
  }

  const root = document.querySelector(':root')
  Object.keys(theme.palette).forEach(color => {
    root.style.setProperty(`--${dashed(color)}-color`, theme.palette[color])
    root.style.setProperty(
      `--${dashed(color)}-color-rgb`,
      hexToRgb(theme.palette[color])
    )
  })

  // colors
  if (theme.colors && theme.colors.length > 0) {
    theme.colors.forEach((color, index) => {
      document.documentElement.style.setProperty(`--color-${++index}`, color);
      document.documentElement.style.setProperty(`--color-${index}-rgb`, hexToRgb(color));
    })
  }

  // font family
  if (theme.fontFamily) {
    applyFontFamily(theme.fontFamily)
  } else {
    applyFontFamily(defaultFontFamily)
  }

  // font load
  if (theme.fontLoad) {
    theme.fontLoad.forEach(font => {
      const { name, path, variants, formats } = font
      loadFont(name, path, variants, formats)
    })
  }

  // filter
  const rgbColor = hexToRgb(theme.palette.primaryText)
  const colorFilter = new ColorFilter(rgbColor[0], rgbColor[1], rgbColor[2]);
  const solver = new Solver(colorFilter);
  const filterResult = solver.solve();
  document.documentElement.style.setProperty(`--color-filter`, filterResult.filter);
}

export const buildOgURL = (filename, language = '', extension = 'png') => {
  filename = filename.replace(/\//g, '')
  if (language) {
    return `${OG_DEFAULT_PATH}/og_${filename}_${language}.${extension}`
  }
  return `${OG_DEFAULT_PATH}/og_${filename}.${extension}`
}

// kebab case
export const kebabCase = s => s.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/[\s_]+/g, '-').toLowerCase();


export const isServer = typeof window === 'undefined';
