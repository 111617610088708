import { audios } from '@data/audio';
import { themes } from '@data/themes';
import { isServer, setThemeVariables } from '@utils/helpers';
import React, { useEffect, useState } from 'react';

export const ThemeContext = React.createContext();

const ThemeProvider = (props) => {
  const DEFAULT_THEME = 'dark'
  const CUSTOM_THEME = 'custom'
  const DEFAULT_AUDIO = 'forest-day'

  const [currentTheme, setTheme] = useState(DEFAULT_THEME);
  const [currentAudio, setAudio] = useState(DEFAULT_AUDIO);
  const [isPlaying, setIsPlaying] = useState(false);
  const applyAudio = (audioId) => {
    const audio = audios.find(audio => audio.id === audioId);
    if (audio) {
      if (currentAudio !== audio.id) setAudio(audio.id);
      if (!isServer) window.localStorage.setItem('audio', audio.id);
    }
  };

  const applyTheme = (themeId) => {
    const theme = themes.find(theme => theme.id === themeId);
    if (theme) {
      setThemeVariables(theme)
      if (currentTheme !== theme.id) setTheme(theme.id);
      if (!isServer) window.localStorage.setItem('theme', theme.id);
    }
  };

  const getCurrentAudio = () => {
    return audios.find(audio => audio.id === currentAudio);
  }

  const getCurrentTheme = () => {
    return themes.find(theme => theme.id === currentTheme);
  }

  // get current theme from local storage
  useEffect(() => {
    if (!isServer) {
      const savedTheme = window.localStorage.getItem('theme');
      if (savedTheme && savedTheme !== CUSTOM_THEME) {
        setTheme(savedTheme)
        applyTheme(savedTheme)
      }
    }
  }, [])

  return (
    <ThemeContext.Provider
      value={{
        currentAudio,
        currentTheme,
        getCurrentAudio,
        getCurrentTheme,
        setAudio,
        setTheme,
        applyAudio,
        applyTheme,
        isPlaying,
        setIsPlaying,
        DEFAULT_THEME,
        CUSTOM_THEME,
        DEFAULT_AUDIO
      }}
    >
      <>{props.children}</>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
