import { useEffect } from "react";
import { isFunction } from '@utils/helpers';

const useOutsideEvent = (ref, handler, condition = true) => {
  useEffect(() => {
    function handleClickOutside(event) {
      const check = isFunction(condition) ? condition() : true
      if (check && ref.current && !ref.current.contains(event.target)) {
        handler()
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default useOutsideEvent
