import React, {useContext} from 'react';
import { ColorPicker } from 'iconoir-react';
import { ThemeContext } from '@context/ThemeProvider';
import classNames from "classnames";
import {useTranslation} from "react-i18next";

export function ThemeToggle({ showLabel, className }) {
  const togglePicker = () => {
    const picker = document.querySelector('.theme-picker');
    picker.classList.toggle('is-open');
  };

  const { getCurrentTheme } = useContext(ThemeContext);
  const theme = getCurrentTheme()
  const { t } = useTranslation();

  const classes = classNames({
    'btn': true,
    'theme-toggle': true,
    'nav-link': true,
    'btn-toggle': !showLabel,
    [className]: className
  });

  return (
    <button onClick={togglePicker} aria-label={t('Theme Toggle')} className={classes}>
      <ColorPicker />
      {showLabel && theme.name}
    </button>
  );
}

export default ThemeToggle;
