import React, { useState, useEffect } from 'react';
import {Link} from "gatsby";
import {Hourglass} from "iconoir-react";
import quotes from '@data/quotes';
import Logo from "../Logo/Logo";
import {QUOTES_PAGE} from "@data/routes";

export const Copyright = () => {
	const [quote, setQuote] = useState('');

	useEffect(() => {
		setQuote(quotes[Math.floor(Math.random() * quotes.length)].quote)
	}, []);

	return (
		<div className="copyright font-light">
			<div className="footer-quotes">
				<Link to={QUOTES_PAGE} className="footer-quote">
					<Hourglass />
					<span className="mt-2">{quote}</span>
				</Link>
			</div>
			<div className="copyright-text">
				<div className="copyright-signature">
					<div className="copyright-logo">
						<Logo gradient steps={8}/>
					</div>
					<span className="ml-2">Entertainment Designed</span>
				</div>
				<span className="mx-2">—</span>
				{new Date().getFullYear()}
			</div>
		</div>
	)
}

export default Copyright
