import React, {useState, useLayoutEffect, useCallback, useContext} from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import Logo from '@components/Logo/Logo';
import Image from "@components/Image/Image";
import AudioToggle from "@components/Audio/AudioToggle";
import ThemeToggle from '@components/Theme/ThemeToggle';
import LanguageToggle from "@components/Language/LanguageToggle";
import { routes } from '@data/routes';
import { useKBar } from "kbar";
import {Search} from "iconoir-react";
import { StateContext } from "@context/StateProvider";
import {useTranslation} from "react-i18next";

export function Nav({ showTitle }) {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [fixed, setFixed] = useState(false);
  const [hidden, setHidden] = useState(false);
  const { state } = useContext(StateContext);
  const { query } = useKBar();
  const { t } = useTranslation();

  const scrollOffset = 50

  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset;
    setFixed(currentScrollPos > scrollOffset);
    setHidden(currentScrollPos > scrollOffset && prevScrollPos < currentScrollPos);
    setPrevScrollPos(currentScrollPos);
  }, [prevScrollPos])

  const renderLinks = (routes, onClick) => {
    return routes.map((link) => link.show && (
      <li className="nav-item" key={link.to}>
        <Link className="nav-link" to={link.to} title={t(link.name)} onClick={onClick} activeClassName="active">
          {link.icon && link.icon}
          <span className="nav-link-label">{t(`nav.${link.name.toLowerCase()}`)}</span>
        </Link>
      </li>
    ));
  };

  const renderCenter = () => {
    if (state && state.showTitle) {
      return (
        <div className="nav-title">
          {state.image &&
            <Image
              className="nav-image"
              src={state.image}
              alt={state.title}
            />
          }
          <h3>{state.title}</h3>
        </div>
      )
    } else {
      return (
        <div className="nav-logo">
          <Link to="/" className="logo" title="Entertainment Design Logo">
            <Logo />
          </Link>
        </div>
      )
    }
  }

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos, fixed, handleScroll]);

  const classes = classNames({
    'nav': true,
    'nav-fixed': fixed,
    'nav-hidden': hidden,
    'has-title': showTitle
  });

  return (
    <div className={classes}>
      <div className="line" />
      <nav className="nav-main">
        <ul>{renderLinks(routes)}</ul>
        {renderCenter()}
        <ul>
          <li className="nav-item">
            <button onClick={() => query.toggle()} className="nav-link" title="Search">
              <Search />
            </button>
          </li>
          <li className="nav-item">
            <ThemeToggle />
          </li>
          <li className="nav-item">
            <AudioToggle />
          </li>
          <li className="nav-item">
            <LanguageToggle />
          </li>
        </ul>
      </nav>
    </div>
  );
}
