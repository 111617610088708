import React, { useState } from 'react';
import { useLocation } from '@reach/router';

export const StateContext = React.createContext();

const StateProvider = (props) => {
  const [state, setState] = useState({
    title: '',
    image: '',
    showTitle: false
  });

  return (
    <StateContext.Provider
      value={{
        state,
        setState,
      }}
    >
      <>{props.children}</>
    </StateContext.Provider>
  );
};

export default StateProvider;
