import React from 'react';
import {useTranslation} from "react-i18next";
import classNames from "classnames";

export function LanguageToggle({ toggle, showLabel, icon, className }) {
  const { t, i18n } = useTranslation();

  const toggleLanguagePicker = (e) => {
    e.preventDefault();
    const menu = document.querySelector('.language-picker');
    menu.classList.toggle('is-open');
    if (toggle) toggle()
  };

  const classes = classNames({
    'btn': true,
    'language-toggle': true,
    'nav-link': true,
    'btn-toggle': !showLabel,
    [className]: className
  });

  return (
    <button aria-label={t('Language Toggle')} onClick={toggleLanguagePicker} className={classes}>
      {!icon && <span className="language-toggle-label">{i18n.language}</span>}
      {icon && icon}
      {showLabel && t(`lang.${i18n.language}`)}
    </button>
  );
}

export default LanguageToggle;
