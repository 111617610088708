import React, { useEffect } from 'react'
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { gsap } from 'gsap'

function Peak({ theme }) {
  let scene, camera, group, controls, autoRotation, renderer, material, color, light

  function initScene(){
    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(90, window.innerWidth / window.innerHeight, 0.5, 10000);
    camera.position.y = 0;
    camera.position.x = 0;
    camera.position.z = 200;
    group = new THREE.Group();
    scene.add(group);

    // Center scene
    gsap.set(group.position, {
      x: 0,
      y: -40,
      z: 0
    });

    // Render Scene
    renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);

    // Controls
    controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.enablePan = false;
    controls.maxPolarAngle = 2;
    controls.minDistance = 50;
    controls.maxDistance = 180;

    // Autorotation
    let firstChange = true;
    controls.addEventListener('change', () => {
      if (firstChange) {
        firstChange = false;
        return;
      }
      gsap.to(autoRotation, {
        timeScale: 0,
        duration: 0.2
      });
    });

    autoRotation = gsap.to(scene.rotation, {
      y: Math.PI * 2,
      ease: 'none',
      duration: 80,
      repeat: -1
    });

    // Setup
    setup()
  }

  function setup () {
    const loader = new THREE.TextureLoader()
    const map = loader.load('/img/textures/map.jpg')
    const geometry = new THREE.PlaneGeometry(500, 500, 128, 128)

    // light
    light = new THREE.AmbientLight(0x000000, 1);
    group.add(light);

    material = new THREE.MeshStandardMaterial({
      wireframe: true,
      opacity: 1,
      displacementMap: map,
      displacementScale: 90
    })

    const plane = new THREE.Mesh(geometry, material)
    plane.rotateX(-Math.PI / 2)
    group.add(plane)
  }

  /* RENDERING */
  function render() {
    requestAnimationFrame(render);
    controls.update();
    renderer.render(scene, camera);
  }

  /* EVENTS */
  function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  }

  useEffect(() => {
    initScene()
    const renderElement = document.getElementById('peak');
    renderElement.appendChild(renderer.domElement);

    window.addEventListener('resize', onWindowResize, false);
    requestAnimationFrame(render);
  }, [])

  return (
    <div id="peak"></div>
  )
}

export default Peak;
