import React from 'react';

const Blocks = () => (
	<div className="blocks-wrapper">
		<svg width="100%" height="100%" viewBox="0 0 950 550" version="1.1" style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinecap: 'round', strokeLinejoin: 'round' }}>
			<defs>
				<linearGradient id="gradient-1" x1="0%" y1="0%" x2="0%" y2="100%">
					<stop offset="0%" className="gradient-step-1" />
					<stop offset="33%" className="gradient-step-2" />
					<stop offset="66%" className="gradient-step-3" />
					<stop offset="100%" className="gradient-step-4" />
				</linearGradient>
				<linearGradient id="gradient-2" x1="0%" y1="0%" x2="0%" y2="100%">
					<stop offset="0%" className="gradient-step-5" />
					<stop offset="33%" className="gradient-step-6" />
					<stop offset="66%" className="gradient-step-7" />
					<stop offset="100%" className="gradient-step-8" />
				</linearGradient>
				<linearGradient id="gradient-3" x1="0%" y1="0%" x2="0%" y2="100%">
					<stop offset="0%" className="gradient-step-9" />
					<stop offset="33%" className="gradient-step-10" />
					<stop offset="66%" className="gradient-step-11" />
					<stop offset="66%" className="gradient-step-12" />
				</linearGradient>
			</defs>
			<g transform="matrix(1,0,0,1,20,-1180)">
				<g transform="matrix(0.9375,0,0,0.507053,0,592.412)">
					<g id="blocks-bottom-row" transform="matrix(1.06667,0,0,1.97218,-545.348,511.209)">
						<g className="block-3d">
							<g transform="matrix(2,0,0,2,578.016,479.846)">
								<g transform="matrix(0.69444,0,0,0.69444,13.688,23.812)">
									<path d="M-19.789,-11.433L-19.789,34.317L19.791,11.447L19.731,-34.313L-19.789,-11.433Z" className="block-3d-face-right" />
									<path d="M-19.789,-11.433L-19.789,34.317L19.791,11.447L19.731,-34.313L-19.789,-11.433Z" className="block-3d-outline" />
								</g>
								<g transform="matrix(0.69444,0,0,0.69444,-13.75,23.812)">
									<path d="M19.752,34.319L-19.748,11.439L-19.748,-34.321L19.752,-11.431L19.752,34.319Z" className="block-3d-face-left" />
									<path d="M19.752,34.319L-19.748,11.439L-19.748,-34.321L19.752,-11.431L19.752,34.319Z" className="block-3d-outline" />
								</g>
								<g transform="matrix(0.69444,0,0,0.69444,0,0)">
									<path d="M-39.509,-0.005L-0.009,22.885L39.511,0.005L0.011,-22.885L-39.509,-0.005Z" className="block-3d-face-top" />
									<path d="M-39.509,-0.005L-0.009,22.885L39.511,0.005L0.011,-22.885L-39.509,-0.005Z" className="block-3d-outline" />
								</g>
							</g>
						</g>
						<g className="block-3d">
							<g transform="matrix(2,0,0,2,704.104,565.593)">
								<g transform="matrix(0.69444,0,0,0.69444,34.312,35.75)">
									<path d="M19.791,11.446L-19.789,34.316L-19.789,-11.434L19.731,-34.314L19.791,11.446Z" className="block-3d-face-right" />
									<path d="M19.791,11.446L-19.789,34.316L-19.789,-11.434L19.731,-34.314L19.791,11.446Z" className="block-3d-outline" />
								</g>
								<g transform="matrix(0.69444,0,0,0.69444,-13.75,23.812)">
									<path d="M49.398,51.467L-49.402,-5.693L-49.402,-51.463L49.398,5.717L49.398,51.467Z" className="block-3d-face-left" />
									<path d="M49.398,51.467L-49.402,-5.693L-49.402,-51.463L49.398,5.717L49.398,51.467Z" className="block-3d-outline" />
								</g>
								<g transform="matrix(0.69444,0,0,0.69444,0,0)">
									<path d="M29.647,40.023L-69.163,-17.147L-29.643,-40.027L69.167,17.153L29.647,40.023Z" className="block-3d-face-top" />
									<path d="M29.647,40.023L-69.163,-17.147L-29.643,-40.027L69.167,17.153L29.647,40.023Z" className="block-3d-outline" />
								</g>
							</g>
						</g>
						<g className="block-3d">
							<g transform="matrix(2,0,0,2,871.282,675.037)">
								<g transform="matrix(0.69444,0,0,0.69444,34.312,35.734)">
									<path d="M-19.789,-11.433L-19.789,34.317L19.791,11.447L19.731,-34.313L-19.789,-11.433Z" className="block-3d-face-right" />
									<path d="M-19.789,-11.433L-19.789,34.317L19.791,11.447L19.731,-34.313L-19.789,-11.433Z" className="block-3d-outline" />
								</g>
								<g transform="matrix(0.69444,0,0,0.69444,-13.719,23.812)">
									<path d="M-49.387,-5.701L49.383,51.469L49.383,5.719L-49.387,-51.471L-49.387,-5.701Z" className="block-3d-face-left" />
									<path d="M-49.387,-5.701L49.383,51.469L49.383,5.719L-49.387,-51.471L-49.387,-5.701Z" className="block-3d-outline" />
								</g>
								<g transform="matrix(0.69444,0,0,0.69444,0,0)">
									<path d="M29.622,40.036L-69.148,-17.154L-29.618,-40.034L69.152,17.156L29.622,40.036Z" className="block-3d-face-top" />
									<path d="M29.622,40.036L-69.148,-17.154L-29.618,-40.034L69.152,17.156L29.622,40.036Z" className="block-3d-outline" />
								</g>
							</g>
						</g>
					</g>
					<g id="blocks-middle-row" transform="matrix(1.06667,0,0,1.97218,-503.964,609.103)">
						<g className="block-3d">
							<g transform="matrix(2,0,0,2,790,416)">
								<g transform="matrix(0.69444,0,0,0.69444,34.312,35.75)">
									<path d="M19.791,11.446L-19.789,34.316L-19.789,-11.434L19.731,-34.314L19.791,11.446Z" className="block-3d-face-right" />
								</g>
								<g transform="matrix(0.69444,0,0,0.69444,-13.75,23.812)">
									<path d="M49.398,51.467L-49.402,-5.693L-49.402,-51.463L49.398,5.717L49.398,51.467Z" className="block-3d-face-left" />
								</g>
								<g transform="matrix(0.69444,0,0,0.69444,0,0)">
									<path d="M29.647,40.023L-69.163,-17.147L-29.643,-40.027L69.167,17.153L29.647,40.023Z" className="block-3d-face-top" />
								</g>
							</g>
						</g>
						<g className="block-3d">
							<g transform="matrix(2,0,0,2,926.843,495.307)">
								<g transform="matrix(0.69444,0,0,0.69444,34.312,35.734)">
									<path d="M-19.789,-11.433L-19.789,34.317L19.791,11.447L19.731,-34.313L-19.789,-11.433Z" className="block-3d-face-right" />
								</g>
								<g transform="matrix(0.69444,0,0,0.69444,-13.719,23.812)">
									<path d="M-49.387,-5.701L49.383,51.469L49.383,5.719L-49.387,-51.471L-49.387,-5.701Z" className="block-3d-face-left" />
								</g>
								<g transform="matrix(0.69444,0,0,0.69444,0,0)">
									<path d="M29.622,40.036L-69.148,-17.154L-29.618,-40.034L69.152,17.156L29.622,40.036Z" className="block-3d-face-top" />
								</g>
							</g>
						</g>
						<g className="block-3d">
							<g transform="matrix(2,0,0,2,1064,574.7)">
								<g transform="matrix(0.69444,0,0,0.69444,34.312,35.734)">
									<path d="M-19.789,-11.433L-19.789,34.317L19.791,11.447L19.731,-34.313L-19.789,-11.433Z" className="block-3d-face-right" />
								</g>
								<g transform="matrix(0.69444,0,0,0.69444,-13.719,23.812)">
									<path d="M-49.387,-5.701L49.383,51.469L49.383,5.719L-49.387,-51.471L-49.387,-5.701Z" className="block-3d-face-left" />
								</g>
								<g transform="matrix(0.69444,0,0,0.69444,0,0)">
									<path d="M29.622,40.036L-69.148,-17.154L-29.618,-40.034L69.152,17.156L29.622,40.036Z" className="block-3d-face-top" />
								</g>
							</g>
						</g>
					</g>
					<g id="blocks-first-row" transform="matrix(1.06667,0,0,1.97218,-396.015,668.983)">
						<g className="block-3d">
							<g transform="matrix(2,0,0,2,911.635,332.181)">
								<g transform="matrix(0.69444,0,0,0.69444,34.312,35.75)">
									<path d="M19.791,11.446L-19.789,34.316L-19.789,-11.434L19.731,-34.314L19.791,11.446Z" className="block-3d-face-right" />
									<path d="M19.791,11.446L-19.789,34.316L-19.789,-11.434L19.731,-34.314L19.791,11.446Z" className="block-3d-outline" />
								</g>
								<g transform="matrix(0.69444,0,0,0.69444,-13.75,23.812)">
									<path d="M49.398,51.467L-49.402,-5.693L-49.402,-51.463L49.398,5.717L49.398,51.467Z" className="block-3d-face-left" />
									<path d="M49.398,51.467L-49.402,-5.693L-49.402,-51.463L49.398,5.717L49.398,51.467Z" className="block-3d-outline" />
								</g>
								<g transform="matrix(0.69444,0,0,0.69444,0,0)">
									<path d="M29.647,40.023L-69.163,-17.147L-29.643,-40.027L69.167,17.153L29.647,40.023Z" className="block-3d-face-top" />
									<path d="M29.647,40.023L-69.163,-17.147L-29.643,-40.027L69.167,17.153L29.647,40.023Z" className="block-3d-outline" />
								</g>
							</g>
						</g>
						<g className="block-3d">
							<g transform="matrix(2,0,0,2,1079.04,440.887)">
								<g transform="matrix(0.69444,0,0,0.69444,34.312,35.734)">
									<path d="M-19.789,-11.433L-19.789,34.317L19.791,11.447L19.731,-34.313L-19.789,-11.433Z" className="block-3d-face-right" />
									<path d="M-19.789,-11.433L-19.789,34.317L19.791,11.447L19.731,-34.313L-19.789,-11.433Z" className="block-3d-outline" />
								</g>
								<g transform="matrix(0.69444,0,0,0.69444,-13.719,23.812)">
									<path d="M-49.387,-5.701L49.383,51.469L49.383,5.719L-49.387,-51.471L-49.387,-5.701Z" className="block-3d-face-left" />
									<path d="M-49.387,-5.701L49.383,51.469L49.383,5.719L-49.387,-51.471L-49.387,-5.701Z" className="block-3d-outline" />
								</g>
								<g transform="matrix(0.69444,0,0,0.69444,0,0)">
									<path d="M29.622,40.036L-69.148,-17.154L-29.618,-40.034L69.152,17.156L29.622,40.036Z" className="block-3d-face-top" />
									<path d="M29.622,40.036L-69.148,-17.154L-29.618,-40.034L69.152,17.156L29.622,40.036Z" className="block-3d-outline" />
								</g>
							</g>
						</g>
						<g className="block-3d">
							<g transform="matrix(2,0,0,2,1204.56,526.634)">
								<g transform="matrix(0.69444,0,0,0.69444,13.688,23.812)">
									<path d="M-19.789,-11.433L-19.789,34.317L19.791,11.447L19.731,-34.313L-19.789,-11.433Z" className="block-3d-face-right" />
									<path d="M-19.789,-11.433L-19.789,34.317L19.791,11.447L19.731,-34.313L-19.789,-11.433Z" className="block-3d-outline" />
								</g>
								<g transform="matrix(0.69444,0,0,0.69444,-13.75,23.812)">
									<path d="M19.752,34.319L-19.748,11.439L-19.748,-34.321L19.752,-11.431L19.752,34.319Z" className="block-3d-face-left" />
									<path d="M19.752,34.319L-19.748,11.439L-19.748,-34.321L19.752,-11.431L19.752,34.319Z" className="block-3d-outline" />
								</g>
								<g transform="matrix(0.69444,0,0,0.69444,0,0)">
									<path d="M-39.509,-0.005L-0.009,22.885L39.511,0.005L0.011,-22.885L-39.509,-0.005Z" className="block-3d-face-top" />
									<path d="M-39.509,-0.005L-0.009,22.885L39.511,0.005L0.011,-22.885L-39.509,-0.005Z" className="block-3d-outline" />
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	</div>
);

export default Blocks;
