import { ThemeContext } from '@context/ThemeProvider';
import { themes } from '@data/themes';
import useOutsideEvent from '@utils/useOutsideEvent';
import classNames from 'classnames';
import { Cancel } from "iconoir-react";
import React, { useContext, useRef } from 'react';
import { useTranslation } from "react-i18next";

export function ThemePicker() {
  const { currentTheme, applyTheme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const pickerRef = useRef(null);

  const closeThemePicker = (e) => {
    if (e) e.preventDefault();
    const menu = document.querySelector('.theme-picker');
    document.body.classList.remove('theme-picker-open')
    menu.classList.remove('is-open');
  };

  const renderItems = () => {
    return themes.map(function (theme) {

      const themeCardClasses = classNames({
        'theme-card': true,
        'active': theme.id === currentTheme,
      })

      if (theme.enable) {
        return (
          <div
            className={themeCardClasses}
            key={theme.id}
            style={{ backgroundColor: theme.palette.primaryBg }}
            onClick={() => applyTheme(theme.id)}
          >
            <span style={{ color: theme.palette.primaryText }}>{theme.name}</span>
            <div className="theme-palette">
              <div className="theme-palette-color" style={{ backgroundColor: theme.palette.secondaryBg }} />
              <div className="theme-palette-color" style={{ backgroundColor: theme.palette.primaryText }} />
              <div className="theme-palette-color" style={{ backgroundColor: theme.palette.secondaryText }} />
              <div className="theme-palette-color" style={{ backgroundColor: theme.palette.accent }} />
            </div>
            <span className="theme-palette-label" style={{ color: theme.palette.primaryText }}>Aa</span>
          </div>
        );
      }
    });
  };

  const isPickerOpen = () => {
    return pickerRef.current.classList.contains('is-open')
  }

  useOutsideEvent(pickerRef, () => {
    const isToggle = event.target.classList.contains('theme-toggle')
    if (isToggle) return
    closeThemePicker()
  }, isPickerOpen)

  return (
    <div ref={pickerRef} className="picker theme-picker">
      <div className="picker-title">{t('Select Theme')}</div>
      <button aria-label={t('Close')} onClick={closeThemePicker} className="picker-close">
        <Cancel />
      </button>
      <div className="theme-list">{renderItems()}</div>
    </div>
  );
}
