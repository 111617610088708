/* eslint-disable no-console */
import React, { useEffect } from 'react'
import { Nav } from '@components/Nav/Nav'
import { MobileNav } from '@components/Mobile/MobileNav'
import { TabNav } from '@components/Mobile/TabNav'
import { ThemePicker } from '@components/Theme/ThemePicker'
import { Footer } from '@components/Footer/Footer'
import { AudioPicker } from "@components/Audio/AudioPicker"
import CookieNotice from '@components/CookieNotice/CookieNotice'
import LanguagePicker from "@components/Language/LanguagePicker"
import { CommandBar } from '@components/CommandBar/CommandBar'
import { motion } from "framer-motion"
import 'react-phone-input-2/lib/style.css'
import '@styles/global.css'
import '@styles/app.scss';

const Layout = ({ children, id, fullscreen }) => {
  const variants = {
    initial: {
      opacity: 0,
      x: -200,
    },
    animate: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      x: 200
    },
    transition: {
      type: "spring",
      mass: 0.35,
      stiffness: 75,
      duration: 0.3
    }
  }

  // page enter
  useEffect(() => {
    console.log(
      `%cEntertainment Designed — ${new Date().getFullYear()}`, 'color:#fff; background: #131317; font-size: 12px; font-weight: 300; padding: .5rem 1.5rem; border-radius: 6px;'
    );
  }, [])

  return (
    <>
      <CommandBar />
      <LanguagePicker />
      <AudioPicker />
      <ThemePicker />
      <CookieNotice />

      <main id="main">
        {!fullscreen && <Nav />}
        {!fullscreen && <MobileNav />}
        <motion.div
          id={id}
          className="content"
          {...variants}
        >
          {children}
        </motion.div>
        {!fullscreen && <Footer />}
        {!fullscreen && <TabNav />}
      </main>
    </>
  );
}

export default Layout
