import React from 'react';

export const LANGUAGES = ['en', 'it']
export const BREAKPOINT_XS = 576
export const BREAKPOINT_SM = 768
export const BREAKPOINT_MD = 960
export const BREAKPOINT_LG = 1280
export const BREAKPOINT_XL = 1440
export const BREAKPOINT_2XL = 1920

export const COMPANY_ADDRESS = {
  street: 'Via Contrada delle Bassiche, 27/A',
  zip: '25122',
  city: 'Brescia BS',
  country: 'Italy'
}
export const COMPANY_EMAIL = 'hi@ed3sign.com'
export const COMPANY_NAME = 'ED3SIGN SRL'
export const REA_NUMBER = 'REA 615216'
export const SHARE_CAPITAL = '€10.000'
export const VAT_NUMBER = 'IT04445810981'
export const RECEIVER_CODE = 'M5UXCR1'
export const WHATSAPP_NUMBER = '+393913381736'
export const OG_DEFAULT_PATH = 'img/social'
export const OG_DEFAULT_IMAGE = `${OG_DEFAULT_PATH}/og_index.png`
