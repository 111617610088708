import CookieConsent from 'react-cookie-consent';
import React from 'react';
import { Link } from "gatsby"
import { HalfCookie } from 'iconoir-react';
import { useMixpanel } from 'gatsby-plugin-mixpanel'
import {useTranslation} from "react-i18next";
import {LEGAL_PAGE} from "@data/routes";

const CookieNotice = () => {
	const { t } = useTranslation();
	const mixpanel = useMixpanel()

	return (
		<CookieConsent
			buttonText={t('cookie.accept')}
			declineButtonText={t('cookie.decline')}
			location="bottom"
			containerClasses="notice"
			buttonWrapperClasses="notice-footer"
			buttonClasses="button btn-accept"
			declineButtonClasses="button btn-decline"
			disableStyles
			hideOnAccept
			enableDeclineButton={true}
			onAccept={() => {
				mixpanel.opt_in_tracking()
			}}
			onDecline={() => {
				mixpanel.opt_out_tracking()
			}}
		>
			<div className="notice-header">
				<span className="icon mr-3">
        	<HalfCookie />
      	</span>
				{t('cookie.header')}
			</div>
			<div className="notice-body">
				{t('cookie.body')}{' '}
				<Link to={LEGAL_PAGE}>
					{t('cookie.privacy')}
				</Link>
			</div>
		</CookieConsent>
	)
}

export default CookieNotice
