import React, { useRef } from 'react';
import { Cancel } from 'iconoir-react';
import useOutsideEvent from '@utils/useOutsideEvent';
import { i18n, useTranslation} from "react-i18next";
import { LANGUAGES } from '@data/config';

export function LanguagePicker() {
  const { t, i18n } = useTranslation();
  const pickerRef = useRef(null);

  const closePicker = (e) => {
    if (e) e.preventDefault();
    const menu = document.querySelector('.language-picker');
    document.body.classList.remove('language-picker-open')
    menu.classList.remove('is-open');
  };

  const renderItems = () => {
    return LANGUAGES.map(function (lang) {
      return (
        <button
          key={lang}
          onClick={() => i18n.changeLanguage(lang)}
          className={i18n.language === lang ? 'language-card active' : 'language-card'}
        >
          <span>{t(`lang.${lang}`)}</span>
          <span className="language-card-code">{lang}</span>
        </button>
      );
    });
  };

  const isPickerOpen = () => {
    return pickerRef.current.classList.contains('is-open')
  }

  useOutsideEvent(pickerRef, (e) => {
    const isToggle = event.target.classList.contains('language-toggle')
    if(isToggle) return
    closePicker()
  }, isPickerOpen)

  return (
    <div ref={pickerRef} className="picker language-picker">
      <div className="picker-title">{t('Select Language')}</div>
      <button aria-label={t('Close')} onClick={closePicker} className="picker-close">
        <Cancel />
      </button>
      <div className="language-list">{renderItems()}</div>
    </div>
  );
}

export default LanguagePicker
